import { Pipe, PipeTransform } from '@angular/core';
import { formatDateToUi } from '../utils/date.util';
import { DATE_UI } from '@shared/constant/date-formats';

@Pipe({
  name: 'georgianDate',
  standalone: true,
})
export class GeorgianDatePipe implements PipeTransform {
  transform(date?: string | Date | null, resultFormat = DATE_UI): string {
    if (!date) return '';
    return formatDateToUi(date, resultFormat);
  }
}
